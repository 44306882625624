.public-home-page {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.public-home-page header h1 {
  font-size: calc(22px + 2vmin);
  color: white;
}

.public-home-contact ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
}

.public-home-contact ul li {
  float: left;
  border-left: 1px solid white;
  padding-right: 10px;
  padding-left: 10px;
}
  .public-home-contact ul > li:first-child {
    border-left: 0px;
    padding-left: 0px;
  }

  .public-home-contact ul > li:last-child {
    padding-right: 0px;
  }
.public-home-contact ul li a {
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  display: block;
}

.public-home-contact ul li a:hover {
  color: #7cb100;
}